import { useEffect, useState, FunctionComponent } from 'react';
import IntroLayout from 'containers/Wizards/MailOrder/Layouts/IntroLayout';
import CreditCardLayout from 'containers/Wizards/MailOrder/Layouts/CreditCardLayout';
import CreditCardBillingLayout from 'containers/Wizards/MailOrder/Layouts/CreditCardBillingLayout';
import CreditCardAuthLayout from 'containers/Wizards/MailOrder/Layouts/CreditCardAuthLayout';
import AllergiesLayout from 'containers/Wizards/MailOrder/Layouts/AllergiesLayout';
import ConditionsLayout from 'containers/Wizards/MailOrder/Layouts/ConditionsLayout';
import OtherMedsLayout from 'containers/Wizards/MailOrder/Layouts/OtherMedsLayout';
import TermsAndConditionsLayout from 'containers/Wizards/MailOrder/Layouts/TermsAndConditionsLayout';
import SubmitLayout from 'containers/Wizards/MailOrder/Layouts/SubmitLayout';
import VerifyPrescriptionLayout from 'containers/Wizards/MailOrder/Layouts/VerifyPrescriptionLayout';
import UploadIDLayout from 'containers/Wizards/MailOrder/Layouts/UploadIDLayout';
import TempSensitiveLayout from 'containers/Wizards/MailOrder/Layouts/TempSensitiveLayout';

import SignatureOptOut from 'containers/Wizards/MailOrder/Layouts/SignatureOptOut';
import HumiraLayout from 'containers/Wizards/MailOrder/Layouts/HumiraLayout';
import WakixLayout from 'containers/Wizards/MailOrder/Layouts/WakixLayout';
import PharmacyConsultLayout from 'containers/Wizards/MailOrder/Layouts/PharmacyConsultLayout';
import InhalerLayout from 'containers/Wizards/MailOrder/Layouts/InhalerLayout';
import VerifyAddressLayout from 'containers/Wizards/MailOrder/Layouts/VerifyAddressLayout';
import SignatureRequiredLayout from 'containers/Wizards//MailOrder/Layouts/SignatureRequiredLayout';

import TertiaryButtonComponent from 'Components/TertiaryButtonComponent/TertiaryButtonComponent';

import {
  useFinalizeMOMutation,
  useUpdatePatientsInfoForMOMutation,
  useGetMailOrderStepsInfoQuery,
} from 'services/mailOrder';

import useStepNavigator from '../hooks/useStepNavigator';
import { WizardProps } from '../types';
import { useHistory } from 'react-router';
import { setIsNextStepReminderEligible } from 'Store/Reducers/appSlice';
import { useDispatch } from 'react-redux';
import useNextStep from 'containers/NextStep/hooks/useNextStep';
import Wizard from '../Wizard';

enum Steps {
  INTRO = 1,
  VERIFY_PRESCRIPTION,
  HUMIRA,
  WAKIX,
  PHARMACY_CONSULT,
  TEMP_SENSITIVE,
  SIGNATURE_REQUIRED,
  SIGN_OPT_OUT,
  INHALER,
  VERIFY_ADDRESS,
  EDIT_ADDRESS,
  CREDIT_CARD,
  CREDIT_CARD_AUTH,
  CREDIT_CARD_BILLING,
  UPLOAD_ID,
  ALLERGIES,
  CONDITIONS,
  OTHER_MEDS,
  TERMS_AND_CONDITIONS,
  SUBMIT,
}

const COMPONENT_MAP: any = {
  [Steps.INTRO]: IntroLayout,
  [Steps.VERIFY_PRESCRIPTION]: VerifyPrescriptionLayout,
  [Steps.HUMIRA]: HumiraLayout,
  [Steps.WAKIX]: WakixLayout,
  [Steps.PHARMACY_CONSULT]: PharmacyConsultLayout,
  [Steps.TEMP_SENSITIVE]: TempSensitiveLayout,
  [Steps.SIGNATURE_REQUIRED]: SignatureRequiredLayout,
  [Steps.SIGN_OPT_OUT]: SignatureOptOut,
  [Steps.INHALER]: InhalerLayout,
  [Steps.VERIFY_ADDRESS]: VerifyAddressLayout,
  [Steps.CREDIT_CARD]: CreditCardLayout,
  [Steps.CREDIT_CARD_BILLING]: CreditCardBillingLayout,
  [Steps.CREDIT_CARD_AUTH]: CreditCardAuthLayout,
  [Steps.UPLOAD_ID]: UploadIDLayout,
  [Steps.ALLERGIES]: AllergiesLayout,
  [Steps.CONDITIONS]: ConditionsLayout,
  [Steps.OTHER_MEDS]: OtherMedsLayout,
  [Steps.TERMS_AND_CONDITIONS]: TermsAndConditionsLayout,
  [Steps.SUBMIT]: SubmitLayout,
};

export interface MailOrderProps {
  contextData: {
    mailOrderAuthKeystring: string;
    isLoggedInUserAlsoPatient: boolean;
    patientFirstName: string;
    isSelfPay: boolean;
    isTemperatureSensitive: boolean;
    signatureRequirement: string;
    drugAllergiesMaybe: string | null;
    medicalConditionsMaybe: string | null;
    currentMedicationsMaybe: string | null;
  };

  nextOnClickCallback: () => void;
  previousOnClickCallback: () => void;
  onSaveData: (payload: Record<string, unknown>) => void;
  onComplete: () => void;
  savedData: Record<string, unknown>;
}

const MailOrderWizard: FunctionComponent<WizardProps> = (props) => {
  const { onComplete } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [savedData, setSavedData] = useState<Record<string, unknown>>({});
  const { nextStep } = useNextStep();
  const [putUpdatePatientInfo] = useUpdatePatientsInfoForMOMutation();
  const [putFinalizeMailOrder] = useFinalizeMOMutation();
  const { data: stepsInfo, isLoading: isMOStepsLoading } = useGetMailOrderStepsInfoQuery('', {
    refetchOnMountOrArgChange: true,
    skip: nextStep == 'Nothing',
  });

  const [dataLoaded, setDataLoaded] = useState(false);

  const [contextData, setContextData] = useState<MailOrderProps['contextData']>({
    mailOrderAuthKeystring: '',
    isLoggedInUserAlsoPatient: false,
    patientFirstName: '',
    isSelfPay: false,
    isTemperatureSensitive: false,
    signatureRequirement: '',
    drugAllergiesMaybe: null,
    medicalConditionsMaybe: null,
    currentMedicationsMaybe: null,
  });

  useEffect(() => {
    if (stepsInfo && !isMOStepsLoading) {
      const newContextData = {
        mailOrderAuthKeystring: stepsInfo.mailOrderId,
        isLoggedInUserAlsoPatient: stepsInfo.isLoggedInUserAlsoPatient,
        patientFirstName: stepsInfo.patientFirstName,
        isSelfPay: stepsInfo.patientPays,
        isTemperatureSensitive: stepsInfo.isTemperatureSensitive,
        signatureRequirement: stepsInfo.signatureRequirement,
        drugAllergiesMaybe: stepsInfo.drugAllergiesMaybe,
        medicalConditionsMaybe: stepsInfo.medicalConditionsMaybe,
        currentMedicationsMaybe: stepsInfo.currentMedicationsMaybe,
      };

      setContextData(newContextData);
      setDataLoaded(true);
    }
  }, [stepsInfo, isMOStepsLoading]);

  const showSignatureRequired = stepsInfo?.signatureRequirement === 'Required';
  const showSignatureOptOut = stepsInfo?.signatureRequirement === 'Recommended';

  const order = [
    Steps.INTRO,
    Steps.VERIFY_PRESCRIPTION,
    stepsInfo?.shouldShowHumiraDisclosure && Steps.HUMIRA,
    stepsInfo?.shouldShowWakixDisclosure && Steps.WAKIX,
    stepsInfo?.containsAnInhaler && Steps.INHALER,
    Steps.PHARMACY_CONSULT,
    showSignatureRequired && Steps.SIGNATURE_REQUIRED,
    showSignatureOptOut && Steps.SIGN_OPT_OUT,
    stepsInfo?.isTemperatureSensitive && Steps.TEMP_SENSITIVE,
    Steps.VERIFY_ADDRESS,
    stepsInfo?.patientPays && Steps.CREDIT_CARD,
    stepsInfo?.patientPays && Steps.CREDIT_CARD_BILLING,
    stepsInfo?.patientPays && Steps.CREDIT_CARD_AUTH,
    stepsInfo?.isPhotoIdRequired && !stepsInfo?.isPhotoIdCompleted && Steps.UPLOAD_ID, //TODO use savedData for ID upload?
    Steps.ALLERGIES,
    Steps.CONDITIONS,
    Steps.OTHER_MEDS,
    Steps.TERMS_AND_CONDITIONS,
    Steps.SUBMIT,
  ].filter(Boolean);

  const { current, next, previous } = useStepNavigator({ steps: order });

  const handleSaveData = (payload: Record<string, unknown>) => {
    setSavedData({
      ...savedData,
      ...payload,
    });
  };

  const handleComplete = async () => {
    if (isMOStepsLoading) {
      return;
    }
    if (stepsInfo) {
      const mailOrderPatientsInfo = {
        mailOrderId: stepsInfo.mailOrderId,
        drugAllergiesMaybe: savedData?.allergies,
        medicalConditionsMaybe: savedData?.conditions,
        currentMedicationsMaybe: savedData?.otherMeds,
      };

      await putUpdatePatientInfo(mailOrderPatientsInfo);
      await putFinalizeMailOrder(stepsInfo.mailOrderId);

      onComplete();
    }
  };

  const handleContinueLater = () => {
    dispatch(setIsNextStepReminderEligible(false));
    history.push('/');
  };

  const Component = COMPONENT_MAP[current];

  return (
    <Wizard isLoading={!dataLoaded}>
      <Component
        contextData={contextData}
        nextOnClickCallback={next}
        previousOnClickCallback={previous}
        onSaveData={handleSaveData}
        onComplete={handleComplete}
        savedData={savedData}
      />
      {current !== Steps.UPLOAD_ID ? (
        <TertiaryButtonComponent text="Continue Later" onClick={handleContinueLater} />
      ) : null}
    </Wizard>
  );
};

export default MailOrderWizard;
