import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'api/client/client';
import { FileNeedInfo } from './file';
import { Address } from './person';

export interface GetIfMailOrderPhotoIdIsRequired {
  isRequired: boolean;
  isCompleted: boolean;
}

export interface MailOrderMedicationFills {
  mailOrderMedicationFillId: string;
  daysSupplyOrdered: number;
  totalCost: number;
  numberOfPackages: number;
  numberOfUnitsPerPackage: number;
  dosageFormUnitType: string;
  isGeneric: boolean;
  pharmacyProductName: string;
  pharmacyProductDescription: string;
  dispensingCountry: string;
}

export interface GetMailOrderVerifyMeds {
  mailOrderMedicationFills: MailOrderMedicationFills[];
  shippingCost?: number;
  medicationCost?: number;
  totalCost?: number;
  isPatientResponsibleForPayment: boolean;
}

export interface MailOrderStepsInfo {
  mailOrderId: string;
  patientPays: boolean;
  isTemperatureSensitive: boolean;
  containsSpecialtyMed: boolean;
  shouldShowHumiraDisclosure: boolean;
  shouldShowWakixDisclosure: boolean;
  containsAnInhaler: boolean;
  isLoggedInUserAlsoPatient: boolean;
  patientFirstName: string;
  isPhotoIdRequired: boolean;
  isPhotoIdCompleted: boolean;
  drugAllergiesMaybe: string | null;
  medicalConditionsMaybe: string | null;
  currentMedicationsMaybe: string | null;
  signatureRequirement: string;
}

export interface BillingAddress extends Address {
  personId: string;
}

export interface CreditCardInfo {
  mailOrderId: string;
  'cc-name': string;
  'cc-number': string;
  'cc-csc': string;
  'cc-expMonth': string;
  'cc-expYear': string;
}

export interface TrackingNumber {
  key: string;
  value: string;
}

export interface TrackingInfo {
  mailOrderId: string;
  medicationList: string;
  trackingNumbers: TrackingNumber[];
}

export const mailOrderApi = createApi({
  reducerPath: 'services/mailOrder',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    authMailOrderInit: builder.mutation({
      query: (initialsInfo) => {
        return {
          url: 'mail-order/auth',
          method: 'post',
          data: initialsInfo,
        };
      },
    }),
    authMailOrderRemoveInit: builder.mutation({
      query: (initialsInfo) => {
        return {
          url: 'mail-order/remove-auth',
          method: 'post',
          data: initialsInfo,
        };
      },
    }),
    medsToVerifyForMO: builder.query<GetMailOrderVerifyMeds, string>({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/verify-meds/${mailOrderKeystring}`,
          method: 'get',
        };
      },
    }),
    updatePatientsInfoForMO: builder.mutation({
      query: (patientsInfo) => {
        return {
          url: 'mail-order/patient-info',
          method: 'put',
          data: patientsInfo,
        };
      },
    }),
    updateShipAddress: builder.mutation({
      query: (shipAddressInfo) => {
        return {
          url: 'mail-order/shipping-address',
          method: 'put',
          data: shipAddressInfo,
        };
      },
    }),
    photoIdRequired: builder.query<GetIfMailOrderPhotoIdIsRequired, string>({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/photo-id-required/${mailOrderKeystring}`,
          method: 'get',
        };
      },
    }),
    photoIdFileNeed: builder.query<FileNeedInfo, string>({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/photo-id-fileNeed/${mailOrderKeystring}`,
          method: 'get',
        };
      },
    }),
    uploadPhotoId: builder.mutation({
      query: (uploadPhotoIdFormData) => {
        return {
          url: 'mail-order/photo-id',
          method: 'put',
          data: uploadPhotoIdFormData,
          header: { 'Content-Type': 'multipart/form-data' },
        };
      },
    }),
    addCreditCardInfo: builder.mutation({
      query: (creditCardInfo: CreditCardInfo) => {
        return {
          url: 'mail-order/cc/add',
          method: 'post',
          data: creditCardInfo,
        };
      },
    }),
    getBillingAddress: builder.query<BillingAddress, string>({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/cc/getBillAddress/${mailOrderKeystring}`,
          method: 'get',
        };
      },
    }),

    getMailOrderStepsInfo: builder.query<MailOrderStepsInfo, string>({
      query: () => {
        return {
          url: `mail-order/get-steps`,
          method: 'get',
        };
      },
    }),

    updateBillingAddress: builder.mutation({
      query: ({ mailOrderKeystring, billingAddress }) => {
        return {
          url: `mail-order/cc/updateBillAddress/${mailOrderKeystring}`,
          method: 'post',
          data: billingAddress,
        };
      },
    }),
    doWeHaveAllScripts: builder.query<boolean, string>({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/scriptMissing/${mailOrderKeystring}`,
          method: 'get',
        };
      },
    }),
    finalizeMO: builder.mutation({
      query: (mailOrderKeystring) => {
        return {
          url: `mail-order/finalize/${mailOrderKeystring}`,
          method: 'put',
        };
      },
    }),
    getTrackingNumbers: builder.query<TrackingInfo[], string>({
      query: (patientPersonId) => {
        return {
          url: `mail-order/getTrackingNumbers/${patientPersonId}`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useAuthMailOrderInitMutation,
  useAuthMailOrderRemoveInitMutation,
  useMedsToVerifyForMOQuery,
  useUpdatePatientsInfoForMOMutation,
  useUpdateShipAddressMutation,
  usePhotoIdRequiredQuery,
  usePhotoIdFileNeedQuery,
  useUploadPhotoIdMutation,
  useAddCreditCardInfoMutation,
  useGetBillingAddressQuery,
  useGetMailOrderStepsInfoQuery,
  useUpdateBillingAddressMutation,
  useDoWeHaveAllScriptsQuery,
  useFinalizeMOMutation,
  useGetTrackingNumbersQuery,
} = mailOrderApi;
